import { Chat } from "@/dto/chat/chat";
import { Message } from "@/dto/chat/message";
import type { NewMessage } from "@/types";
import { useFetchStateStore } from "@/store/fetch-state";

export const useChatStore = defineStore("chat", () => {
  const chats = ref<Chat[]>([]);
  const activeChatId = ref<number>();
  const user = useSupabaseUser();
  const fetchStateStore = useFetchStateStore();

  const totalUnreadMessages = computed(() =>
    chats.value?.reduce((totalUnread, chat) => getUnreadMessagesForChat(chat) + totalUnread, 0)
  );

  const fetching = computed(() => fetchStateStore.fetching["chats"]);

  const getChats = async (employerId: string) => {
    await fetchStateStore.fetchOnce("chats", async () => {
      chats.value = await $api<Chat[]>(`${API_ROUTES.chat.index}/${employerId}`, {});
    });
  };

  const findOrCreateChat = async (newChat: {
    participants: string[];
    applicant: { id: string };
    job: { id: number };
    application: { id: number };
  }): Promise<Chat | Chat> => {
    try {
      const existingChat = chats.value.find(
        (chat) => chat.applicant_id === newChat.applicant.id && chat.job_id === newChat.job.id
      );

      if (existingChat) return existingChat;

      const chat = await $api<Chat>(API_ROUTES.chat.index, {
        method: "post",
        body: newChat,
      });

      if (chat) chats.value.push(chat);
      return chat;
    } catch (e) {
      throw createError({ message: ERROR_MESSAGES.error_opening_chat });
    }
  };

  const deleteChat = async (chatId: number) => {
    await $api<Partial<Chat>>(`${API_ROUTES.chat.index}/${chatId}`, {
      method: "delete",
    });
  };

  const inviteTeamMembers = async (chatId: number, memberIds: string[]) => {
    await $api<Chat>(`${API_ROUTES.chat.index}/${chatId}`, {
      method: "patch",
      body: memberIds,
    });
  };

  const sendMessage = async (newMessage: NewMessage) => {
    const chatIndex = chats.value.findIndex((c: Chat) => c.id === newMessage.chat.id);
    const currentChat = chats.value[chatIndex];

    currentChat.messages.unshift({ ...newMessage, sender_id: user.value!.id } as Message);

    const message = await $api<Message>(API_ROUTES.chat.message, {
      method: "post",
      body: newMessage,
    });

    if (message) {
      const messageIndex = chats.value[chatIndex].messages.findIndex(
        (m: Message) => m.unique_id === message.unique_id
      );
      currentChat.messages[messageIndex] = message;
    }

    return message;
  };

  const updateMessage = async (messageId: number, message: Message | Partial<Message>) => {
    await $api<Message>(`${API_ROUTES.chat.message}/${messageId}`, {
      method: "patch",
      body: message,
    });
  };

  const markAllAsRead = async (chatId: number, applicantId: string) => {
    await $api<Message[]>(API_ROUTES.chat.markAllAsRead, {
      method: "patch",
      body: { chatId, applicantId },
    });
  };

  const addMessageToStore = (message: Message) => {
    if (!message) throw createError({ message: ERROR_MESSAGES.message_not_found });

    const chat = chats.value.find((c: Chat) => c.id === message.chat_id);

    if (!chat) return;

    const messageIndex = chat.messages.findIndex((m: Message) => m.id === message.id);
    messageIndex !== -1 ? (chat.messages[messageIndex] = message) : chat.messages.unshift(message);
  };

  const getChatIndex = ({ chat, applicationId }: { chat?: Chat; applicationId?: number }) => {
    if (chat) return chats.value.findIndex((c: Chat) => c.id === chat.id);
    if (applicationId) return chats.value.findIndex((c: Chat) => c.application_id === applicationId);
    return -1;
  };

  const addChatToStore = (chat: Chat) => {
    const index = getChatIndex({ chat });
    if (index !== -1) {
      const existingChat = chats.value[index];
      chats.value[index] = {
        ...chat,
        messages: existingChat.messages || []
      };
    } else {
      chats.value.unshift({
        ...chat,
        messages: []
      });
    }
  };

  const removeChatFromStore = ({ chat, applicationId }: { chat?: Chat; applicationId?: number }) => {
    const index = getChatIndex({ chat, applicationId });
    index !== -1 ? chats.value.splice(index, 1) : null;
  };

  const getUnreadMessagesForChat = (chat: Chat) =>
    chat.messages?.filter((m) => !m.read && m.sender_id !== user.value?.id && m.type !== MESSAGE_TYPE.system)
      .length ?? 0;

  const getLastMessageForChat = (chat: Chat) => chat.messages?.at(0);

  return {
    chats,
    fetching,
    activeChatId,
    getChats,
    findOrCreateChat,
    sendMessage,
    deleteChat,
    updateMessage,
    addMessageToStore,
    inviteTeamMembers,
    addChatToStore,
    removeChatFromStore,
    totalUnreadMessages,
    getUnreadMessagesForChat,
    getLastMessageForChat,
    markAllAsRead,
  };
});
