import { Application, ApplicationMatchPercentage } from "@/dto/application/application";
import { useChatStore } from "@/store/chat";
import { useFetchStateStore } from "@/store/fetch-state";

export const useApplicationStore = defineStore("application", () => {
  const applications = ref<Application[]>([]);
  const selectedApplication = ref<Application | null>(null);
  const fetchStateStore = useFetchStateStore();
  const jobFilter = ref<{ id: number; name: string }>();
  const unreadFilter = ref<boolean>(false);
  const percentageMatchFilter = ref<{ id: number; name: string, min: number, max: number }>();
  
  const totalUnreadApplications = computed(() => applications.value.filter((app) => !app.read).length);
  const fetching = computed(() => fetchStateStore.fetching["applications"]);
  const filtersActive = computed(() => jobFilter.value || unreadFilter.value);
  const applicationsByStatus = computed(() => {
    const statusMap = {
      invited: APPLICATION_STATUS.invited,
      applied: APPLICATION_STATUS.applied,
      shortlisted: APPLICATION_STATUS.shortlisted,
      hired: APPLICATION_STATUS.hired,
      declined: APPLICATION_STATUS.declined,
    } as const;
    
    
    const filteredAndSortedApps = filterApplications(applications.value).sort((a, b) => 
      (b.match?.percentage ?? 0) - (a.match?.percentage ?? 0)
    );
    
    return Object.entries(statusMap).reduce((acc, [key, status]) => ({
      ...acc,
      [`${key}Applications`]: filteredAndSortedApps.filter((app) => app.status.name === status),
    }), {} as Record<`${keyof typeof statusMap}Applications`, Application[]>);
  });

  
  const filterApplications = (apps: Application[]) => {
    return apps.filter((app) => {
      const isSelectedJob = !jobFilter.value || app.job.id === jobFilter.value.id;
      const isUnread = !unreadFilter.value || !app.read;
      const isPercentageMatch = !percentageMatchFilter.value || 
        ((app.match?.percentage ?? 0) >= percentageMatchFilter.value.min && 
         (app.match?.percentage ?? 0) < percentageMatchFilter.value.max);
      return isSelectedJob && isUnread && isPercentageMatch;
    });
  };

  const getApplications = async () => {
    await fetchStateStore.fetchOnce("applications", async () => {
      applications.value = await $api<Application[]>(API_ROUTES.application.index);
    });
  };

  const invite = async (appData: Partial<Application>) => {
    const application = await $api<Application>(API_ROUTES.application.create, {
      method: "post",
      body: appData,
    });
    return application;
  };

  const inviteMany = async (appData: { job_id: number; profile_ids: string[]; status_id: number, match_percentage: number }) => {
    const applications = await $api<Application>(API_ROUTES.application.createMany, {
      method: "post",
      body: appData,
    });
    return applications;
  };

  const updateApplication = async (appData: Partial<Application>, appId: number) => {
    const application = await $api<Application>(`${API_ROUTES.application.index}/${appId}`, {
      method: "patch",
      body: appData,
    });
    if (application) {
      const index = applications.value.findIndex((a: Application) => a.id === appId);
      applications.value[index] = application;
    }
  };

  const deleteApplication = async (appId: number) => {
    const application = await $api<Partial<Application>>(`${API_ROUTES.application.index}/${appId}`, {
      method: "delete",
    });
    if (application && application.job_id && application.profile_id) {
      removeApplicationFromStore(application.job_id, application.profile_id);
      const chatStore = useChatStore();
      chatStore.removeChatFromStore({ applicationId: application.id });
    }
  };

  const syncApplicationMatchPercentage = (applicationMatchPercentage: ApplicationMatchPercentage) => {
    const index = applications.value.findIndex((a: Application) => a.id === applicationMatchPercentage.application_id);
    if (index !== -1) applications.value[index].match = applicationMatchPercentage;
  };

  const addApplicationToStore = (application: Application) => {
    if (!application) throw createError({ message: ERROR_MESSAGES.application_not_found });
    const index = applications.value.findIndex((a: Application) => a.id === application.id);
    index !== -1 ? (applications.value[index] = application) : applications.value.unshift(application);
  };

  const removeApplicationFromStore = (jobId: number, profileId: string) => {
    const index = applications.value.findIndex((a: Application) => a.job_id === jobId && a.profile_id === profileId);
    if (index !== -1) applications.value.splice(index, 1);
  };

  const getApplicationByPrimaryKeys = (jobId: number, profileId: string) => {
    return applications.value.find((a: Application) => a.job_id === jobId && a.profile_id === profileId);
  };

  return {
    applications,
    fetching,
    selectedApplication,
    getApplications,
    updateApplication,
    deleteApplication,
    invite,
    inviteMany,
    addApplicationToStore,
    removeApplicationFromStore,
    totalUnreadApplications,
    getApplicationByPrimaryKeys,
    jobFilter,
    unreadFilter,
    percentageMatchFilter,
    filtersActive,
    applicationsByStatus,
    syncApplicationMatchPercentage
  };
});
